import React from 'react'

import styles from './OnboardingPage.module.scss'
import { CheckMark } from 'components/shared/Icons'
import stripe_img from '../../../static/img/stripe/stripe_integration.png'
import Button from 'components/shared/Button'
import { FormContainer } from 'components/shared/Forms'
import Card from 'components/shared/Card'

export default ({ redirect }) => (
  <FormContainer>
    <Card>
      <p className={styles.mainTxt}>
        To recieve payment for your rentals, you need to connect a bank account
        or debit card. All payments are processed securely by our partner
        Stripe.
      </p>
      <div className={styles.stripeSection}>
        <img
          src={stripe_img}
          className={styles.stripeIntegration}
          height="90"
        />
        <div className={styles.featureGroup}>
          <CheckMark className={styles.paymentsCheck} />
          <p className={styles.featureTitle}>Secure</p>
          <p className={styles.featureDetails}>
            Transfer of your private information is encrypted end-to-end
          </p>
        </div>
        <div className={styles.featureGroup}>
          <CheckMark className={styles.paymentsCheck} />
          <p className={styles.featureTitle}>Private</p>
          <p className={styles.featureDetails}>
            Your credentials will never be made accessible to Borrowed
          </p>
        </div>
      </div>
      <Button big full onClick={redirect}>
        Connect with Stripe
      </Button>
    </Card>
  </FormContainer>
)
