/**
 * Onbarding page (collects bank account information)
 * For right now it's just a button that
 * redirects to stripe-express.
 */

import React from 'react'

import KEYS from 'constants/keys'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'

import OnboardingPage from 'components/OnboardingPage'

class OnboardingBase extends React.Component {
  // Redirects to the appropriate stripe express url
  redirectToExpress = () => {
    const { email, uid, phoneNumber = undefined } = this.props.currentUser

    const params = {
      //redirect_uri: `${window.location.href}/redirect`, // hardcoded in stripe dashboard
      client_id: KEYS.STRIPE.CONNECT_ID,
      // state: <CSRF_TOKEN>, // TODO: Generate and validate CSRF token
      // TODO: Prefill User's name, email, phone number, etc...
    }
    const p = new URLSearchParams(params)
    const href = `https://connect.stripe.com/express/oauth/authorize?${p.toString()}`
    window.location.href = href
  }

  render() {
    return (
      <div className="base">
        <div className="content-wrap">
          <section className="form-title page-title">
            <h2>Payments with Mellow</h2>
          </section>
          <OnboardingPage redirect={this.redirectToExpress} />
        </div>
      </div>
    )
  }
}

export default props => (
  <Layout {...props} displayFooter={false} showBeacon={true}>
    <SEO title="Payments with Mellow" />
    <OnboardingBase {...props} />
  </Layout>
)
